<template>
  <div id="app" :style="cssProps">
    <div class="content">
      <NavBarTop class="NavBarTop p-0" v-if="!$route.meta.hideNavbarTop" />
      <NavBar class="mb-2 py-1" />
      <div class="container">
        <transition name="slide-fade" mode="out-in">
          <router-view :key="$route.fullPath" />
        </transition>
      </div>
      <a
        v-if="showScrollButton"
        href="#"
        id="return-top"
        v-scroll-to="{ el: '#app', offset: 80 }"
      >
        <BaseIcon name="arrow-up-circle" width="40" height="40" />
      </a>
      <Footer />
    </div>
  </div>
</template>

<script>
import NavBarTop from "@/components/header_top.vue";
import NavBar from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import DataService from "@/services/DataService";
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  name: "default-app",
  components: {
    NavBarTop,
    NavBar,
    Footer,
  },
  data() {
    return {
      scrollHeight: 0,
      interval: null,
      showScrollButton: false,
      color: "",
      btnBGGcolor: "",
      btnTXXcolor: "",
      test: "#000000"
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    initLang() {
      this.companyData.languages.forEach(function (index) {
        if (index != "") {
          DataService.getCustomLang(index, this.companyData.apiKey, this.companyData.tableOrderToken).then(
            (response) => {
              if(response.data){
                Vue.i18n.add(index, response.data);
              }
            }
          );
        }
        Vue.i18n.set(this.companyData.defaultLanguage);
      }.bind(this));
    },
    handleScroll() {
      if (window.top.scrollY > 400) {
        this.showScrollButton = true;
      } else {
        this.showScrollButton = false;
      }
    },
    contrast(hexcolor) {
      hexcolor = hexcolor.replace("#", "");
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "#111111" : "#ffffff";
    },
    RGBnr(hexcolor) {
      hexcolor = hexcolor.replace("#", "");
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "0" : "255";
    },
    hexToHSL(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      var r = parseInt(result[1], 16);
      var g = parseInt(result[2], 16);
      var b = parseInt(result[3], 16);
      (r /= 255), (g /= 255), (b /= 255);
      var max = Math.max(r, g, b),
        min = Math.min(r, g, b);
      var h,
        s,
        l = (max + min) / 2;
      if (max == min) {
        h = s = 0; // achromatic
      } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
          case g:
            h = (b - r) / d + 2;
            break;
          case b:
            h = (r - g) / d + 4;
            break;
        }

        h /= 6;
      }
      return [Math.ceil(h * 360), Math.ceil(s * 100), Math.ceil(l * 100)];
    },
  },
  computed: {
    ...mapGetters(["companyData"]),
    shopHeader() {
      var col = this.companyData.shopHeaderColorRgb;
      var reg=/^#([0-9a-f]{3}){1,2}$/i;
      if(reg.test(col)){
        return col;
      }else{
        return '#ffffff';
      }
    },
    shopButtons() {
      var col =  this.companyData.shopGroupButtonsColorRgb;
      var reg=/^#([0-9a-f]{3}){1,2}$/i;
      if(reg.test(col)){
        return col;
      }else{
        return '#ffffff';
      }
    },

    status() {
      return !!this.companyData.shopBackgroundColorRgb;
    },
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
    cssProps() {
      var themeProbs = 0;
      if (this.status) {
        var hexn = this.RGBnr(this.companyData.shopBackgroundColorRgb);
        var h = this.hexToHSL(this.shopHeader)[0];
        var s = this.hexToHSL(this.shopHeader)[1];
        var l = this.hexToHSL(this.shopHeader)[2];
        var h2 = this.hexToHSL(this.shopButtons)[0];
        var s2 = this.hexToHSL(this.shopButtons)[1];
        var l2 = this.hexToHSL(this.shopButtons)[2];
        var text = (l > 50) ? "#111111":"#f5f5f5";
        var text2 = (l2 > 50) ? "#111111":"#f5f5f5";
        var lighter = (l2 < 50) ? l2 + 10 : l2 - 20;
        var darker = (l > 50) ? l + 20 : l - 20;
        var desaturate70 = s -70;
        var textDarker = (darker > 50) ? "#111111":"#f5f5f5";
        var primary = h + 180;

        themeProbs = {
          "--headerBGcolor": "hsl(" + h + "," + s + "%," + l + "%)",
          "--headerTXcolor": text,

          "--footerBGcolor": "hsl("+ h + "," + desaturate70 + "%," + darker + "%)",
          "--footerTXcolor": textDarker,

          "--bodyBGcolor": this.companyData.shopBackgroundColorRgb,
          "--bodyTXcolor": this.contrast(this.companyData.shopBackgroundColorRgb),

          "--btnBGcolor": "hsl("+ primary + "," + s + "%," + l + "%)",
          "--btnTXcolor": text,
          "--btnHVcolor": "hsl(" + primary + "," + s + "%," + darker + "%)",

          "--groupBGcolor": "hsl(" + h2 + "," + s2 + "%," + l2 + "%)",
          "--groupTXcolor": text2,
          "--groupHVcolor": "hsl(" + h2 + "," + s2 + "%," + lighter + "%)",

          "--bodybgtile": "url(" + JSON.stringify(this.companyData.shopBackgroundImage) + ")",
          "--panel0": "rgba(" + hexn + ", " + hexn + ", " + hexn + ", 0.3)",
          "--panel": "rgba(" + hexn + ", " + hexn + ", " + hexn + ", 0.1)",
          "--panel1": "rgba(" + hexn + ", " + hexn + ", " + hexn + ", 0.02)",
          "--panel2": "rgba(" + hexn + ", " + hexn + ", " + hexn + ", 0.03)",
          "--panel3": "rgba(" + hexn + ", " + hexn + ", " + hexn + ", 0.04)",
        };
      } else {
        themeProbs = {
          "--headerBGcolor": "#161616",
          "--headerTXcolor": "#161616",
          "--footerBGcolor": "#454545",
          "--footerTXcolor": "#cccccc",
          "--bodyBGcolor": "#ffffff",
          "--bodyTXcolor": "#111111",
          "--btnBGcolor": "#252525",
          "--btnTXcolor": "#252525",
          "--btnHVcolor": "#212121",
          "--groupBGcolor": "#656565",
          "--groupTXcolor": "#000000",
          "--groupHVcolor": "#616161",
          "--bodybgtile": "none",
          "--panel": "rgba(0, 0, 0, 0.1)",
          "--panel1": "rgba(0, 0, 0, 0.02)",
          "--panel2": "rgba(0, 0, 0, 0.03)",
          "--panel3": "rgba(0, 0, 0, 0.04)",
        };
      }
      return themeProbs;
    },
    siteName() {
      return this.companyData.companyName;
    },
    siteDescription() {
      return this.companyData.shopDescription;
    },
  },
  watch: {
    isIdle(status) {
      if (status == true && this.$route.path != "/stores") {
        window.localStorage.clear();
        window.location.href = "/stores";
      }
    },
  },
  beforeMount() {
    this.initLang();
  },
};
</script>
