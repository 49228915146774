import axios from "axios";
import NProgress from "nprogress";

const apiClient = axios.create({
  baseURL: "https://apiv1.xenia-pos.net/api/",
});

apiClient.interceptors.request.use((config) => {
  NProgress.configure({
    showSpinner: true,
  });
  NProgress.start();
  return config;
});

apiClient.interceptors.response.use((response) => {
  NProgress.done();
  return response;
});

export default {
  getAllProducts(key) {
    return apiClient.get("/Article/" + key);
  },

  getStore(key) {
    apiClient.defaults.headers.common["x-api-key"] = key;
    return apiClient.get("/Setup");
  },

  getStoreBlog() {
    return apiClient.get("/Blog");
  },

  getStoreLegal() {
    return apiClient.get("/Blog/imprint");
  },

  getStorePrivacy() {
    return apiClient.get("/Blog/gdpdu");
  },

  getStoreAgb(lang) {
    return apiClient.get("/Blog/agb/" + lang );
  },

  createOrder(cart_content, token) {
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return apiClient.post("/CreateOrder", cart_content);
  },

  makePayment(data, token, key){
    apiClient.defaults.headers.common["x-api-key"] = key;
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return apiClient.post("/order", data);
  },

  PaymentPrepare(data, token, key) {
    apiClient.defaults.headers.common["x-api-key"] = key;
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return apiClient.post("/PaymentPrepare", data);
  },

  checkHotelClient(data, token, key) {
    apiClient.defaults.headers.common["x-api-key"] = key;
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return apiClient.get("/HotelReservation" + data);
  },

  PaymentStatus(data, token, key) {
    apiClient.defaults.headers.common["x-api-key"] = key;
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return apiClient.get("/PaymentStatus/" + data);
  },

  getOrders(key, token) {
    apiClient.defaults.headers.common["x-api-key"] = key;
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return apiClient.get("/Orders");
  },

  getOrder(table, key, token) {
    apiClient.defaults.headers.common["x-api-key"] = key;
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return apiClient.get("/order/" + table);
  },

  getCustomLang(lang, key, token){
    apiClient.defaults.headers.common["x-api-key"] = key;
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return apiClient.get("/Localize/" + key + "/" + lang);
  }
};
