<template>
  <div>
    <BaseIcon
      name="shopping-cart"
      width="25"
      height="25"
      class="my-1 mr-2"
      style="float: right"
    />
    <h3>{{ "Rechnung Bezahlen" | translate }}</h3>
    <div v-show="tryAgain" class="cart panel p-2">
      <div class="panel t1 p-2">
        <div class="text-center">
          <img src="/assets/img/error.png" style="width: 50px;" /> {{ $t("payment_error") }}
        </div>
        <div class="row mx-1 d-flex justify-content-between my-1">
          <div class="col-lg-12 m-0 text-center">
            <button
              v-show="tryAgain"
              class="btn btn-lg btn-danger m-0"
              @click="checkout()"
            >
              {{ "Wiederholen" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-show="paySuccess" class="cart panel p-2">
      <div class="panel t1 p-2 text-center">
        <h2>{{ "Vielen Dank" | translate }}</h2>
        {{ $t("payment_success1") }}
        <ul style="list-style-position: inside;margin-left:-50px;">
          <li><em>{{"Betrag" | translate}}</em>: <strong>{{ total | currency(cSymb) }}</strong></li>
          <li><em>{{"Bestell Nr" | translate}}</em>: <strong>{{paymentId}}</strong></li>
        </ul>
        <div class="text-center mt-2">
          <button
            class="btn btn-lg btn-danger m-0"
            @click="goHome()"
          >
            {{ "Zur Karte" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataService from "@/services/DataService";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "app-billsuccess",
  components: {},
  data() {
    return {
      PayMethod: 0,
      paymentStatus: "",
      paySuccess: false,
      spinner: false,
      tryAgain: false,
    };
  },
  computed: {
    ...mapGetters(["billData", "companyData"]),
    AllowHobex() {
      if (this.companyData.pspType == 2) {
        return true;
      } else {
        return false;
      }
    },
    AllowWalle() {
      if (this.companyData.pspType == 3) {
        return true;
      } else {
        return false;
      }
    },
    TableToken() {
      return this.companyData.tableOrderToken;
    },
    cSymb() {
      if (this.companyData.storeCurrency === "EUR") {
        return "€";
      } else if (this.companyData.storeCurrency === "USD") {
        return "$";
      } else {
        return this.companyData.storeCurrency;
      }
    },
    total() {
      return this.billData.Amount;
    },
    paymentId(){
      return this.billData.PosOrderId;
    }
  },
  methods: {
    ...mapActions(["removeAllFromCart", "clearOrder"]),
    sendOrder() {
      var data = {
        PosOrderId: this.paymentId,
        PayMethod: this.PayMethod,
        Amount: this.total
      };
      DataService.makePayment(
        data,
        this.companyData.tableOrderToken,
        this.companyData.apiKey
      ).then((response) => {
        if(response.data.errorMessage == null){
          this.error = "";
          this.tryAgain = false;
          //window.localStorage.clear();
        }else{
          this.error = response.data.errorMessage;
        }
      });
    },
    checkout() {
      this.$router.push({ path: "/checkout" });
    },
    goHome() {
      setTimeout(() => {
        this.$router.push({ path: "/" });
      }, 300);
    },
  },
  created() {
    if (this.AllowHobex) {
      DataService.PaymentStatus(
        this.billData.PosOrderId,
        this.companyData.tableOrderToken,
        this.companyData.apiKey
      ).then((response) => {
        this.PayMethod = 2;
        this.paymentStatus = response.data.status;
        if (response.data.status === "Success") {
          this.paySuccess = true;
          this.spinner = true;
          this.tryAgain = false;
          this.sendOrder();
        } else {
          this.paySuccess = false;
          this.spinner = false;
          this.tryAgain = true;
        }
        console.log(response.data);
      });
    };
    if (this.AllowWalle) {
      DataService.PaymentStatus(
        this.billData.walleeTid,
        this.companyData.tableOrderToken,
        this.companyData.apiKey
      ).then((response) => {
        this.PayMethod = 3;
        this.paymentStatus = response.data.status;
        if (response.data.status === "Success") {
          this.paySuccess = true;
          this.spinner = true;
          this.tryAgain = false;
          this.sendOrder();
        } else {
          this.paySuccess = false;
          this.spinner = false;
          this.tryAgain = true;
        }
        console.log(response.data);
      });
    }
  },
};
</script>
