<template>
  <div>
    <h2>{{ "AGB" | translate }}</h2>
    <div class="row">
      <div class="col-lg-12">
        <div class="panel p-2">
          <div
            class="panel t1 p-3 mb-2"
            v-for="(item, index) in agbData"
            :key="index"
          >
            <div class="date color">
              <h5>{{ item.date }}</h5>
            </div>
            <div v-html="item.html"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataService from "@/services/DataService.js";
import { mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "app-agb",
  data() {
    return {
      agbData: [],
    };
  },
  created() {
    var lang = Vue.i18n.locale();
    DataService.getStoreAgb(lang).then((response) => {
      this.agbData = response.data;
    });
  },
  computed: {
    ...mapGetters(["companyData"]),
  },
};
</script>
